function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cardList",class:[
    { 'customOffset-right': _vm.offsetRight },
    { 'customOffset-left': _vm.offsetLeft } ]},_vm._l((_vm.items),function(ref){
  var id = ref.id;
  var rest = objectWithoutProperties( ref, ["id"] );
  var item = rest;
return _c('staff-card-list-item',_vm._b({key:id,staticClass:"cardList__item"},'staff-card-list-item',item,false))}),1)}
var staticRenderFns = []

export { render, staticRenderFns }