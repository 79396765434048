var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sponsors"},[_c('app-title',{staticClass:"sponsors__title",attrs:{"id":"organizer"}},[_vm._v("# ORGANIZERS")]),_c('div',{staticClass:"sponsors__section"},_vm._l((_vm.organizers),function(ref){
var name = ref.name;
var link = ref.link;
var width = ref.width;
var height = ref.height;
return _c('lazy-component',{key:name},[_c('img',{staticClass:"sponsors__section--image",attrs:{"alt":name,"src":link,"width":width,"height":height}})])}),1),_c('app-title',{staticClass:"sponsors__title"},[_vm._v("# CO-ORGANIZERS")]),_c('div',{staticClass:"sponsors__section"},_vm._l((_vm.co_organizers),function(ref){
var name = ref.name;
var link = ref.link;
var width = ref.width;
var height = ref.height;
return _c('lazy-component',{key:name},[_c('img',{staticClass:"sponsors__section--image",attrs:{"alt":name,"src":link,"width":width,"height":height}})])}),1),_c('app-title',{staticClass:"sponsors__title",attrs:{"id":"sponsors"}},[_vm._v("# SPONSORS")]),_c('div',{staticClass:"sponsors__section"},_vm._l((_vm.sponsors),function(ref){
var name = ref.name;
var link = ref.link;
var width = ref.width;
var height = ref.height;
return _c('lazy-component',{key:name},[_c('img',{staticClass:"sponsors__section--image",attrs:{"alt":name,"src":link,"width":width,"height":height}})])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }